import { countryCurrencyMapper } from "resources/countries-config.json";

type CurrencyCode = {
  country: string;
  postalCode: Maybe<string>;
  userType: Maybe<string>;
};

const getCurrencyCode = ({ country, postalCode, userType }: CurrencyCode) => {
  return countryCurrencyMapper[country];
};

export default getCurrencyCode;
