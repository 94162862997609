// Function to replace placeholders in HTML
const replaceShortCodePlaceholders = (input: any = "", shortCodes = {}) => {
  // Replace raw placeholders like <%= key %>
  let replacedText = input.replace(/<%=\s*(\w+)\s*%>/g, (match, key) => {
    return key in shortCodes ? shortCodes[key] : match;
  });

  // Replace HTML-escaped placeholders like &lt;%= key %&gt;
  replacedText = replacedText.replace(
    /&lt;%=\s*(\w+)\s*%&gt;/g,
    (match, key) => {
      return key in shortCodes ? shortCodes[key] : match;
    }
  );

  return replacedText;
};

export { replaceShortCodePlaceholders };
